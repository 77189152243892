import React, { useState } from 'react'
import './About.css'
import { FaThumbsUp, FaHandsHelping, FaMapMarked } from 'react-icons/fa'
import { RxDoubleArrowUp } from 'react-icons/rx'
import { motion } from 'framer-motion'

function Aboutus() {
    const [scrollBtn, setScrollbtn] = useState(false)
    const changeBackground = () => {
        if (window.scrollY >= 230) {
            setScrollbtn(true)
        } else {
            setScrollbtn(false)
        }
    }

    window.addEventListener('scroll', changeBackground)
    return (
        <>
            <section className="AboutUs-conatainer" id='aboutus'>

                {scrollBtn && <div className='scrollContainer'>
                    <a href="#header"> <RxDoubleArrowUp /></a>
                </div>}
                <div className='Aboutus_heading'>
                    <h2> We partner with you through each step of the process</h2>
                    <span className="section-title-line"></span>
                </div>
                <div className='AboutusSection_container'>
                    <motion.section className='sections' 
                        initial={{ opacity: 0.6 ,y:80}}
                        whileInView={{ opacity: 1,y:0 }}
                        viewport={{ once: true }}
                        transition={{duration:1.5}}>
                        <div className='iconContainer'>
                            <FaThumbsUp className='icons' />
                        </div>
                        <div className='icon-text'>
                            <h4>Simple</h4>
                            <p>Book, track and manage payments for your shipments. For any help, your Customer Success Expert is a call away. </p>

                        </div>
                    </motion.section>
                    <motion.section className='sections'
                        initial={{ opacity: 0.6 ,y:80}}
                        whileInView={{ opacity: 1,y:0 }}
                        viewport={{ once: true }}
                        transition={{duration:1.5}}
                    >
                        <div className='iconContainer'>
                            <FaHandsHelping className='icons handsHelpingicon' />
                        </div>
                        <div className='icon-text'>
                            <h4>Reliable</h4>
                            <p>Our team of experts work with you to ensure that your shipments are delivered correctly and on time.</p>
                        </div>
                    </motion.section>
                    <motion.section className='sections' 
                        initial={{ opacity: 0.6 ,y:80}}
                        whileInView={{ opacity: 1,y:0 }}
                        viewport={{ once: true }}
                        transition={{duration:1.5}}
                    >
                        <div className='iconContainer'>
                            <FaMapMarked className='icons' />
                        </div>
                        <div className='icon-text'>
                            <h4>Transparent</h4>
                            <p>Our technology stack catches issues early to resolve them quickly, keeping you updated, ensuring no surprises.</p>

                        </div>
                    </motion.section>
                </div>
            </section>
        </>
    )
}

export default Aboutus
