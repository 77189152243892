import React, { useState } from "react";
import "./Faq.css";
function Faq() {
    const [xhipToggle, setXhipToggle] = useState(false);
    const [getToggle, setgetToggle] = useState(false);
    const [hiringToggle, sethiringToggle] = useState(false);
    const [servicesToggle, setServicesToggle] = useState(false);
    const [tradeToggle, settradeToggle] = useState(false);
    const [wareToggle, setwareToggle] = useState(false);


    const showToggleText = (e) => {
        if (Number(e.target.id) === 0) {
            setXhipToggle(!xhipToggle);
        } else if (Number(e.target.id) === 1) {
            setServicesToggle(!servicesToggle);
        } else if (Number(e.target.id) === 2) {
            setwareToggle(!wareToggle);
        } else if (Number(e.target.id) === 3) {
            settradeToggle(!tradeToggle);
        } else if (Number(e.target.id) === 5) {
            sethiringToggle(!hiringToggle);
        } else if (Number(e.target.id) === 4) {
            setgetToggle(!getToggle);
        }
    };


    return (
        <div className="faqConatiner" id="faq">
            <div className="faqHeader">
                <h2>FREQUENTLY ASKED QUESTIONS</h2>
                <span className="underline"></span>
            </div>
            <div className="faqsections">
                <section className="faqsection">
                    <p
                        id="0"
                        onClick={showToggleText}
                        className={xhipToggle ? "qText rotate" : "qText"}
                    >
                        What is Xhipment?
                    </p>
                    {xhipToggle && (
                        <div
                            className="databind flex "
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <p style={{ letterSpacing: "0.9px" }} >We are a Digital Freight Forwarding and Supply Chain Management Platform. We work closely with our customers, operating at the intersection of cutting-edge technology simplifying global supply chains and a customer obsessed team to deliver a world class Xhipment experience.
                                <br /><br />
                                Whether you’re an experienced or new Amazon/Walmart seller, exporting or importing to the US while navigating international regulations is a complex process. Our background and experience allows us to advise you on the quickest, safest, most cost-effective route to get your cargo shipped to Amazon/Walmart warehouses.
                                <br /><br />
                                Our founding team comprises ex-Amazon Supply Chain and Technology Leaders who have built and scaled multiple cross-border transportation solutions across continents, shipping over ~$3B GMV of cargo.</p>
                        </div>
                    )}
                </section>

                <section className="faqsection">
                    <p
                        id="1"
                        onClick={showToggleText}
                        className={servicesToggle ? "qText sm1 rotate" : "qText sm1"}

                    >
                        What type of services does Xhipment offer to exporters shipping to Amazon & Walmart?
                    </p>
                    {servicesToggle && (
                        <div className="databind">
                            <p style={{ letterSpacing: "1px" }} >
                                Our <a href="#services">services</a> include: Ocean FCL, Ocean LCL, Air Freight, Drayage, Trucking, Customs Clearance, Warehousing and Cargo Insurance.

                            </p>
                        </div>
                    )}
                </section>

                <section className="faqsection">
                    <p
                        id="2"
                        onClick={showToggleText}
                        className={wareToggle ? "qText sm1 rotate" : "qText sm1"}
                    >
                        Which Amazon & Walmart warehouses does Xhipment service?
                    </p>
                    {wareToggle && (
                        <div className="databind">
                            <p style={{ letterSpacing: "1px" }} >
                                We provide cross border transportation and fulfillment services to all Amazon US & Walmart US warehouses.</p>
                        </div>
                    )}
                </section>

                <section className="faqsection">
                    <p
                        id="3"
                        onClick={showToggleText}
                        className={tradeToggle ? "qText sm1 rotate" : "qText sm1"}
                    >
                      Which trade lanes does Xhipment currently support? 
                    </p>
                    {tradeToggle && (
                        <div className="databind">
                            <p style={{ letterSpacing: "1px" }} >
                                Our services are currently focused on India to US and UK to US trade
                                lanes. We are rapidly expanding to other trade lanes and
                                geographies in 2024.
                            </p>
                        </div>
                    )}
                </section>

                <section className="faqsection">
                    <p
                        id="4"
                        onClick={showToggleText}
                        className={getToggle ? "qText rotate" : "qText"}
                    >
                        How do I get started?
                    </p>
                    {getToggle && (
                        <div className="databind">
                            <p style={{ letterSpacing: "1px" }} >
                                Join our invite only program by sharing your contact details{" "}
                                <a href="#signup">
                                    <span>here</span>
                                </a>
                                . We will get in touch with you to get started.
                            </p>
                        </div>
                    )}
                </section>

                <section className="faqsection">
                    <p
                        id="5"
                        data-toggle="collapse"
                        href="#collapseExample"
                        role="button"
                        data-target="#collapseExample"
                        aria-controls="collapseExample"
                        onClick={showToggleText}
                        className={hiringToggle ? "qText rotate" : "qText"}
                    >
                        Are you hiring?
                    </p>
                    {hiringToggle && (
                        <div className="databind ">
                            <p style={{ letterSpacing: "1px" }} >
                                Yes, we are hiring across Sales, Operations, Product and
                                Engineering roles. Drop us a note at hiring@xhipment.com and we
                                will setup time to chat. Coffee is on us :)
                            </p>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
}

export default Faq;
