import React from "react";
import "./Testimonial.css";
import { motion } from "framer-motion";

function Testimonials() {
  const testimonials = [
    {
      company: "Mahi Exports",
      customer: "Ram",
      industry: "Textiles Exporter",
      service: "Ocean Freight",
      quote:
        "Xhipment is providing customers with very competitive rates for both LCL and FCL shipments. Very good price with even better service. We feel that Xhipment has filled a huge gap in the market by providing Amazon Sellers with various services.",
      highlight: "Competitive pricing for ocean freight",
    },
    {
      company: "Eximious",
      customer: "Naina",
      industry: "Handicrafts Exporter",
      service: "Amazon Shipment",
      quote:
        "Our experience starting from onboarding to handling to delivery was not just smooth but also well structured. We are also glad with the rate of the shipment in comparison with other shipping agencies. In all very happy all round.",
      highlight: "Very happy all round Amazon shipment service",
    },
    {
      company: "Adorntex",
      customer: "Rashi",
      industry: "Pillows Exporter",
      service: "International Ocean Shipping",
      quote:
        "The international ocean shipping service from India to the US was top-notch. Our shipment arrived on time and in excellent condition. The communication throughout the process was clear and helpful. Overall, we were extremely satisfied with the service and would highly recommend it.",
      highlight: "On-time delivery and clear communication",
    },
  ];

  return (
    <>
      <section className="Testimonials-conatainer" id="testimonials">
        <div className="Testimonial_heading text-center">
          <h2> Customer Satisfaction: Our Reviews Speak for Themselves</h2>
          <span className="section-title-line"></span>
        </div>
        <div className="TestimonialSection_container">
          {testimonials.map((testimonial) => (
            <motion.section
              className="sections testimonial-section"
              key={testimonial.index}
              initial={{ opacity: 0.6, y: 80 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1.5 }}
            >
              <svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="quotes-icon"
              >
                <path
                  d="M20.3306 0.748192C21.3238 0.446722 22.2422 1.25987 22.2422 2.2978V2.2978C22.2422 3.16636 21.5699 3.86062 20.7885 4.23986C20.3914 4.4326 20.0266 4.68528 19.6942 4.99793C19.0313 5.66024 18.5134 6.50882 18.1405 7.54368C17.809 8.53714 17.6433 9.6134 17.6433 10.7724H19.8693C20.9739 10.7724 21.8693 11.6679 21.8693 12.7724V17.3411C21.8693 18.4456 20.9739 19.3411 19.8693 19.3411H15.1687C14.0642 19.3411 13.1687 18.4456 13.1687 17.3411V11.5796C13.1687 9.09597 13.5831 7.06765 14.4117 5.49466C15.2817 3.92167 16.4004 2.72124 17.7676 1.89335C18.5782 1.4025 19.4326 1.02078 20.3306 0.748192ZM7.33743 0.762106C8.33058 0.451098 9.25349 1.26456 9.25349 2.30526V2.30526C9.25349 3.17072 8.5849 3.86516 7.8134 4.25733C7.43874 4.44777 7.09014 4.69464 6.76761 4.99793C6.10471 5.66024 5.58682 6.50882 5.21394 7.54368C4.88249 8.53714 4.71676 9.6134 4.71676 10.7724H6.94275C8.04732 10.7724 8.94275 11.6679 8.94275 12.7724V17.3411C8.94275 18.4456 8.04732 19.3411 6.94275 19.3411H2.24219C1.13762 19.3411 0.242188 18.4456 0.242188 17.3411V11.5796C0.242188 9.09597 0.6565 7.06765 1.48513 5.49466C2.35518 3.92167 3.47383 2.72124 4.84106 1.89335C5.63799 1.41079 6.47012 1.03371 7.33743 0.762106Z"
                  fill="#FF4F00"
                />
              </svg>

              <div className="testimonial-text text-start">
                <h4>"... {testimonial.highlight} ..."</h4>
                <p>{testimonial.quote}</p>
              </div>
              <div className="testimonial-author text-start p-0 m-0">
                <p className="p-0 m-0">
                  <b>
                    <em>
                      {" "}
                      {testimonial.customer} - {testimonial.industry}{" "}
                    </em>
                  </b>
                </p>
              </div>
            </motion.section>
          ))}
        </div>
      </section>
    </>
  );
}

export default Testimonials;
