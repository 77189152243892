import React, { useEffect } from "react";
import "./Header.css";
import BgImg from "../../assests/backgroungImg.png";
import Navbar from "../Navbar/Navbar";
import amazonSENDLogo from '../../assests/amazonSPNLogo.png'
import wlmartLogo from '../../assests/Walmart Logo 1.png'
import { ProvContext } from "../../context/Context";
import mixpanel from "mixpanel-browser"
import newSpnLogo from '../../assests/send_logo.png'
// import isoSvg from '../../assests/iso.svg'
mixpanel.init(`${process.env.REACT_APP_MIXPANELID}`, {
    debug: true,
    ignore_dnt: true
})

function Header() {
    const { setMixpanelId } = ProvContext()

    useEffect(() => {
        mixpanel.track("# Visits to homepage")
        let id = mixpanel.get_distinct_id()
        setMixpanelId(id)
    }, [setMixpanelId])

    return (
        <div className="wrapper home-default-wrapper" id="header">
            <Navbar />
            <main className="main-content">
                <div className="bg-image-container">
                    <img src={BgImg} alt="background_image" />
                </div>
                <div className="img-overlay"></div>
                <section className="header-content">
                    <div className="wrap-one">
                        <h2 >
                            Ship to US with the Best Amazon & Walmart Freight Forwarder
                        </h2>
                    </div>
                    <div className="wrap-two">
                        <p >
                            Our team of experts makes delivering inventory to Amazon & Walmart US warehouses a seamless process for exporters and importers alike
                        </p>
                        <p className="mt-2">Click{" "}<a href="#signup">here</a> to express your interest</p>
                        <br />
                        <p className="small-heading mt-2">Partners with</p>

                        <div className="amazon_logo ">
                        <div className="LogoContainer">
                            <a target={"blank"} href="https://sell.amazon.in/grow-your-business/amazon-global-selling/fulfillment/send#carriers">
                                <img
                                    className="amazon_spn_logo  "
                                    src={newSpnLogo}
                                    alt="Amazon SPN Logo"
                                />
                                </a>
                            </div>
                            <div className="LogoContainer">
                                <a href="https://sellercentral.amazon.in/gspn/provider-details/International%20Shipping/37844545-5d80-4530-b890-25e6d9e454ba?ref_=sc_gspn_islst_isdt-37844545&localeSelection=en_US&sellFrom=IN&sellIn=IN" target={"blank"}>
                                    <img
                                        className="amazon_spn_logo "
                                        src={amazonSENDLogo}
                                        alt="Amazon SPN Logo"
                                    />
                                </a>
                            </div>
                            <div className="LogoContainer">
                                <img
                                    className="walmart_logo "
                                    src={wlmartLogo}
                                    alt="Walmart Logo"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {/* <div className="iso_logo">
                <img src={isoSvg} alt="ISO Logo" />
            </div> */}
            </main>
        </div>
    );
}

export default Header;
