import React, { useState, useEffect } from 'react'
import './index.css'
import HeaderLogo from '../../assests/xhipment-logo.png'
import { useNavigate } from 'react-router-dom';
import { RxDoubleArrowUp } from 'react-icons/rx'
import Footer from '../../Footer/Footer';

function Privacy() {
    const [navbar, setNavbar] = useState(false);
    const [scrollBtn, setScrollbtn] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.scrollY >= 100) {
            window.scrollTo(-10, -10);
        }
    }, []);
    const changeBackground = () => {
        if (window.scrollY >= 90) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    const navigateToTop = () => {
        if (window.scrollY >= 230) {
            setScrollbtn(true)
        } else {
            setScrollbtn(false)
        }
    }
    window.addEventListener("scroll", changeBackground);
    window.addEventListener("scroll", navigateToTop);


    return (
        <div id='Privacy' className='privacy_container'>
            <header className={navbar ? "navbar-wrapper active" : "navbar-wrapper "}>
                <div className="container">
                    <div className="navbar-container">
                        <div className="logo-containers">
                            <div className="header-logo-area">
                                <a href="#header" onClick={() => { navigate('/') }}>
                                    <img
                                        className="logo-light"
                                        src={HeaderLogo}
                                        alt="Logo"
                                        width="161"
                                        height="48"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {scrollBtn && <div className='scrollContainer'>
                <a style={{ color: "white" }} href="#Privacy"> <RxDoubleArrowUp /></a>
            </div>}
            <div className='container privacy-body px-md-5 px-3'>
                <div className='privacy-heading'>
                    <h3>Privacy Policy</h3>
                    <div className='Effective'>
                        <h2>Effective Date of the current Privacy Policy: 27 July 2022</h2>
                        <div className='privacy-heading-text'>
                            <p>We completely understand the responsibility of protecting Your privacy. We designed this Privacy Policy to tell You about the Company’s practices regarding collection, use, and disclosure of information that You may provide for using Our Services/accessing the Platform. By using/accessing the Platform and by providing any information to Us through the Platform, You agree to the terms of this Privacy Policy.</p>
                            <p>This Privacy Policy of Holaport Logistics Private Limited (“Privacy Policy”) will govern Your use of the Platform and its Services. By a</p>
                            <p>In this Privacy Policy, meanings as ascribed to the terminologies in the Terms of Use or Terms of Service shall be applicable.</p>
                        </div>
                    </div>
                    <div className='revisions'>
                        <h2>Revisions to Privacy Policy:</h2>
                        <p>We reserve the right to update and revise this Privacy Policy at any time. You can determine if this Privacy Policy has been revised since Your last visit by referring to the “Effective Date of the current Privacy Policy” date at the top of this page. You should therefore review this Privacy Policy regularly to ensure that You are aware of its terms and amendments. Your use of the Platform constitutes Your acceptance of the terms of the Privacy Policy as amended or revised by Us.</p>
                    </div>
                    <div className='General'>
                        <h2>General:</h2>
                        <p>This Privacy Policy covers Our treatment of Information that the Company collects and receives, including Information related to Your past use of the Platform. Technology and security policies, rules and other precautions are used to shield Your Information from unlawful intruders, unauthorized access, destruction and accidental loss. Our Privacy Policy is designed to assist You in understanding how We collect, use and safeguard the Information You provide to Us and to assist You in making informed decisions when using Our Platform.</p>
                    </div>

                    <div className='Information'>
                        <h2>Information collected by Us</h2>
                        <p>Our primary goal of collecting Your Information is to provide You a safe, efficient, smooth and customized experience. This allows the Company to provide Services and features that most likely meet Your needs, and to customize Our Platform to make Your experience safer and easier.</p>
                    </div>

                    <div className='visit'>
                        <h4>When You visit the Platform, You may provide us with Your</h4>
                        <div className='personal'>
                            <h6>Personal Information:</h6>
                            <p>Establishment Name, mobile number, address, bank details and content which You knowingly disclose and is collected on an individual basis.</p>
                        </div>

                        <div className='sensitive'>
                            <h6>Sensitive Financial Information:</h6>
                            <p>Sensitive financial data or information of a person means such personal information which consists of information relating to passwords, other payment instrument details, and national identifiers including but not limited to: PAN, GST etc.</p>
                        </div>

                        <div className='Automatic'>
                            <h6>Automatic Information</h6>
                            <p>We receive and store certain information such as Your location, which automatically becomes accessible to Us by virtue of You accessing the Platform on Your device. Additionally, like many other Platforms, We use Cookies and obtain certain types of information when Your device accesses the Platform or advertisements and other content served by or on behalf of the Platform.</p>
                            <p>Cookie is a small data file that is sent to or accessed from Your device when You use the Platform (“Cookies”). The use of Cookies helps us to assist Your use of certain aspects of the Platform. You can delete Cookies at any time, or You can set Your device to reject or disable Cookies. We may use information from Cookies for the following purposes:</p>
                            <ul>
                                <li>Traffic monitoring</li>
                                <li>IP address from which You access the Platform</li>
                                <li>Type of device and operating system used to access the Platform</li>
                                <li>Date and time of Your access to the Platform</li>
                                <li>Pages that You visit</li>
                            </ul>
                        </div>

                        <div className='stored'>
                            <h6>Stored Information:</h6>
                            <p>We store all data that is shared by You or by a third party on Your behalf such as image files, documents etc. either at the time of accessing the Platform or on information provided by You through social media platforms such as but not limited to WhatsApp and Instagram or at a later stage.</p>
                            <p>Personal Information, Sensitive Financial Information, Automatic Information and Stored Information shall individually be referred to as “Personal Information”, “Sensitive Financial Information” “Automatic Information”, “Stored Information” and collectively as “Information”.</p>
                            <p>The User on using of the Platform and the Services provides its consent for the Company to access the Sensitive Financial Information of the User in accordance with the terms of this Privacy Policy. If You do not wish to provide consent for usage of its sensitive financial data or information or later withdraws the consent, the Company shall have the right not to provide services or to withdraw the services for which the information was sought from You.</p>
                            <p>Information does not refer to any content that You may share with Us for the purposes of publishing on Our Platform. Such content shall be governed by Our Terms of Use and Terms of Service.</p>
                        </div>
                        <div className='useOfInfo'>
                            <h6>Use of Information:</h6>
                            <ul>
                                <li>We use Your Information for understanding Users in such a way as to provide better Services of the Platform</li>
                                <li>We use Your Information to customize Your experience; detect and protect Us against error, fraud and other criminal activity, enforce Our terms and conditions, and as otherwise described to You at the time of collection</li>
                                <li>To send You communication, as per Your notification settings, on any updates or developments pertaining to the Platform or Your use of the Platform;</li>
                                <li>To provide You with technical support, including Platform updates; and</li>
                                <li>To facilitate payment processes and verify payment information.</li>
                            </ul>
                        </div>

                        <div className='TypesOfInfo'>
                            <h6>Types of information not allowed on the Platform</h6>
                            <ul>
                                <li>Nudity or other sexually suggestive content</li>
                                <li>Hate speech</li>
                                <li>Credible threats or direct attacks on an individual or group</li>
                                <li>Content that contains self-harm or excessive violence</li>
                                <li>Content that is deemed illegal by the governing laws</li>
                                <li>Fake or impostor profiles; and</li>
                                <li>Spam.</li>
                            </ul>
                        </div>

                        <div className='InfoSharing'>
                            <h6>Information Sharing Policy</h6>
                            <p>Generally, the Company will not disclose personally identifiable information that We collect through Your use of the Platform to unaffiliated third parties. We reserve the right, however, to provide such information to Our affiliates, third party contractors, employees, contractors, agents, and designees to the extent necessary to enable them to perform certain Platform related Services (such as improving Platform related Services and features, or maintenance Services) on Our behalf.</p>
                            <p>We also reserve the right to disclose such information to any third party if We believe that We are required to do so for any or all of the following reasons: (i) by law; (ii) to comply with legal processes or governmental requests; (iii) to prevent, investigate, detect, or prosecute criminal offenses or attacks on the technical integrity of the Platform or Our network; and/or (iv) to protect the rights, property, or safety of or Our employees, the Users of the Platform, the public, or You.</p>
                        </div>

                        <div className='ChangesOfBusiness'>
                            <h6>Changes of business ownership and control:</h6>
                            <p>The Company may, from time to time, expand or reduce Our business and this may involve the sale and/or the transfer of control of all or part of the Company. Information provided will, where it is relevant to any part of Our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the Information for the purposes for which it was originally given to Us.</p>
                            <p>We may also disclose Information to a prospective purchaser of Our business or any part of it.</p>
                            <p>In the above instances, we will take steps to ensure that Your privacy is protected.</p>
                        </div>

                        <div className='DataRetention'>
                            <h6>Data retention:</h6>
                            <p>Unless a longer retention period is required or permitted by law, we will only hold Your Information on Our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy.</p>
                            <p>Even if We delete Your Information, it may persist on backup or archival media for legal, tax or regulatory purposes</p>
                        </div>

                        <div className='technical'>
                            <h6>Technical and functional management of the Platform:</h6>
                            <p>When You use Our Platform, We may also process technical data such as Your IP address, Device ID or MAC address, and information about the manufacturer, model, and operating system of Your mobile device. We use this data to enable us to deliver the functionalities of the Platform, resolve technical difficulties, and provide You with the correct (prevent the transmission of spam, viruses and/or unlawful, defamatory, obscene or otherwise undesirable material or content) and most up to date version of the Platform.</p>
                        </div>
                        <div className='Security'>
                            <h6>Security:</h6>
                            <p>The Platform takes Your security seriously and We take precautions to keep Your Information secure. We have put in place appropriate physical, electronic and managerial procedures to safeguard the information that We collect. However, due to the open communication nature of the internet, We cannot guarantee that communications between You and the Company, or information stored on Our Platform, will be free from unauthorized access by third parties. Any transmission of information by You to Our Platform is at Your own risk.</p>
                        </div>

                        <div className='LegalDisc'>
                            <h6>Legal Disclaimer:</h6>
                            <p>We reserve the right to disclose Your personally identifiable information as required by law and when we believe that disclosure is necessary to protect Our rights and/or to comply with a judicial proceeding, court order or legal process served on Our Platform. We may be required to share the aforesaid information with government authorities and agencies for the purposes of verification of identity or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences. You agree and consent for the Company to disclose Your Information, if so required under the applicable law.</p>
                        </div>
                        <div className="specialNote">
                            <h6>A Special Note about Children:</h6>
                            <p>The Services under the Platform are not directed towards children under 18 (eighteen). They are not allowed to register with or use the service or provide personal data. If We discover that We have collected personal information from a child under 18, We will delete that information at the earliest.</p>
                        </div>
                        <div className='GoverningLaw'>
                            <h6>Governing Law:</h6>
                            <p>The laws of India shall govern this Privacy Policy, regardless of Your location. You hereby expressly consent to exclusive jurisdiction and venue located in Delhi, India, for all matters or disputes arising out of or relating to the Terms of Use, Terms of Service or Your access or use of the Platform. ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE TERMS OF USE OR YOUR USE OF THE PLATFORM MUST BE BROUGHT BY YOU WITHIN ONE (1) MONTH OF THE DATE SUCH CAUSE OF ACTION FIRST AROSE, OR SUCH CAUSE OF ACTION IS FOREVER WAIVED BY YOU. EACH CAUSE OF ACTION SHALL BE ADJUDICATED INDIVIDUALLY, AND YOU AGREE NOT TO COMBINE YOUR CLAIM WITH THE CLAIM OF ANY THIRD PARTY.</p>
                        </div>

                        <div className='Grievance'>
                            <h6>Grievance Redressal:</h6>
                            <p>If You find any discrepancies or have any grievances in relation to the collection, storage, use, disclosure and transfer of Your Personal Information under this Privacy Policy or other terms and conditions or policies of any of the Company’s entity, please contact Us at support@xhipment.com.</p>
                        </div>

                        <div className='Clauses'>
                            <h6>CLAUSES SPECIFIC TO USERS WHO ARE DOMICILED IN EUROPE</h6>
                            <p>While we process your data, we strictly adhere to the General Data Protection Regulation (“GDPR”) currently in force throughout Europe and available to all citizens of the European Union. You can assert the following rights against us within the scope of the GDPR in relation to your personal data:</p>
                            <ul>
                                <li>Your right to information and access in accordance with Article 15 of the GDPR,</li>
                                <li>Your right to rectification under Article 16 GDPR</li>
                                <li>Your right to erasure under Article 17 GDPR</li>
                                <li>Your right to restriction of processing under Article 18 GDPR and</li>
                                <li>Your right to data portability under Article 20 GDPR</li>
                                <li>You also have a right to lodge a complaint the competent data protection supervisory authority (Article 77 GDPR in conjunction with § 19 BDSG).</li>
                                <li>You can also withdraw a given declaration of consent to the processing of your personal data at any time. Please note that this declaration cannot work retrospectively</li>
                            </ul>
                        </div>

                        <div className='california endText'>
                            <h6>CLAUSES SPECIFIC TO USERS WHO ARE DOMICILED IN CALIFORNIA</h6>
                            <p>The laws in force in the state of California, United States of America, requires the Company to state the various rights that the California Consumer Privacy Act (“CCPA”) provides to those people who are domiciled in California. The CCPA grants state residents’ certain rights, including the rights to access specific types of personal data, to learn how we process personal data, to request deletion of personal data, and not to be denied goods or services for exercising these rights.</p>
                            <p>If you are an authorized agent wishing to exercise rights on behalf of a California resident, please contact us and provide a copy of the consumer’s written authorization designating you as their agent.</p>
                            <p>We may need to verify your identity and place of residence before completing your CCPA rights request.</p>
                        </div>

                    </div>

                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Privacy;
