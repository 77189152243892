import { useContext, createContext, useState } from 'react'
const Contextprov = createContext()

export const AuthContextProvider = ({ children }) => {
    const [closeSidebar, setCloseSidebar] = useState(false)
    const [mixpanelId,setMixpanelId]=useState("")
    return (
        <Contextprov.Provider value={{
            closeSidebar, setCloseSidebar,
            setMixpanelId,mixpanelId
        }}>
            {children}
        </Contextprov.Provider>
    )
}

export const ProvContext = () => {
    return useContext(Contextprov)
}