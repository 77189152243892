import "./App.css";
import { useEffect } from "react";
import {
    Aboutus,
    Header,
    Services,
    Faq,
    TalkToExpert,
    Footer,
    Testimonials,
    License,
    Tracking,
} from "./Components";
import { AuthContextProvider } from "./context/Context";

function App() {

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);

    if (params.get('src') === 'signup') {
      // scroll to #signup id
      const element = document.getElementById('signup');
      element.scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" });
    }
  }, []);

    return (
        <AuthContextProvider>
            <div className="App">
                <Header />
                <License />
                <Tracking />
                <Aboutus />
                <Services />
                <Testimonials />
                <Faq />
                <TalkToExpert />
                <Footer />
            </div>
        </AuthContextProvider>
    );
}

export default App;
