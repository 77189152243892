import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Terms from "./Components/Terms";
import Privacy from "./Components/privacy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Notfound from "./Notfound";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "bootstrap/dist/js/bootstrap.bundle.min";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/Home/Terms" element={<Terms />}></Route>
      <Route path="/Home/Privacy" element={<Privacy />}></Route>
      <Route path="/*" element={<Notfound />} status={404} />
    </Routes>
  </BrowserRouter>
);
