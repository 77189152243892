import React from 'react'
import './Sidebar.css'
import { RxCross2 } from 'react-icons/rx'
import { ProvContext } from '../../context/Context'
function Sidebar() {
    const { closeSidebar, setCloseSidebar } = ProvContext();
    const closebar = () => {
        setCloseSidebar(false)
    }
    return (
        <>
            <section onClick={() => { setCloseSidebar(false) }} className={closeSidebar ? 'sidebar_container' : 'close'}>
                <div onClick={(e) => {
                    e.stopPropagation()
                }} className={closeSidebar ? "sidebar sideactive" : "sidebar close"}>
                    <div className='cross' onClick={(e) => {
                        setCloseSidebar(false)
                    }}>
                        <RxCross2 className='cross-icon' />
                    </div>
                    <ul className='navbar'>
                        
                        <li onClick={closebar} className='navbarLists'><a href="#aboutus">About Us</a></li>
                        <li onClick={closebar}><a href="#services">Services</a></li>
                        <li onClick={closebar}><a href="#faq">FAQs</a></li>
                        <li onClick={closebar}><a href="#signup">Sign Up</a></li>
                        <li onClick={closebar}><a href='#tracking'>Track Your Xhipment</a></li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Sidebar
