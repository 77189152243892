import React, { useState } from "react";
import "./TalkToExpert.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mixpanel from "mixpanel-browser";
mixpanel.init(`${process.env.REACT_APP_MIXPANELID}`, {
  debug: true,
  ignore_dnt: true,
});
function TalkToExpert() {
  const [data, setData] = useState({
    name: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    shippingMode: "",
    shipToUs: "",
    shipFrom: "",
  });



  
  const submitForm = () => {
    if (
      !data.name ||
      !data.email ||
      !data.companyName ||
      !data.shippingMode ||
      !data.shipToUs || 
      !data.shipFrom
    ) {
      toast.error("Please enter valid Details", {
        role: "alert",
      });
    } else {

        // check email by email regex and return error
        var email = data.email;
        var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      if (!emailReg.test(email)) {  
        toast.error("Please enter valid email")
        return
      };
      fetch(`${process.env.REACT_APP_BASE_URL}/v1/auth/contactus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((result) => result.json())
        .then((result) => {
          //   console.log(result);
          mixpanel.track("# SignUp form submissions ", {
            name: data.name,
            email: data.email,
          });
          if (result.message === "Company already registered") {
            toast.success(
              "Your company details have already been received. Please write to us at support@xhipment.com for further information."
            );
          } else if (result.success === false) {
            toast.error(
              "Something went wrong. Please write to us at support@xhipment.com "
            );
          } else {
            if (data.shipToUs === "No") {
              toast.success(
                "Thank you for sharing your information. We currently do not service non-US trade lanes but are continuously updating our services. Our customer success team will reach out to you then."
              );
            } else {
              toast.success(
                "Your query has been registered. We will reach out to you with a response shortly"
              );
            }
          }

          setData({
            name: "",
            companyName: "",
            email: "",
            phoneNumber: "",
            shippingMode: "",
            shipToUs: "",
            shipFrom: "",
          });
        });
    }
  };
  return (
    <div className="talkContainer" id="signup">
      <div className="talkHeader">
        <h2>Sign Up</h2>
        <span className="underline"></span>
      </div>
      <div className="talkFormConatiner">
        <form>
          <div className="inputs">
            <input
              className="input"
              type="text"
              name="name"
              value={data.name}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
              placeholder="Name *"
            />
            <input
              className="input"
              type="text"
              name="companyName"
              value={data.companyName}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
              placeholder="Company Name *"
            />
            <input
              className="input"
              type="text"
              name="email"
              value={data.email}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
              placeholder="Email *"
            />
            <input
              className="input"
              type="text"
              name="phoneNumber"
              value={data.phoneNumber}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
              placeholder="Phone Number "
            />
            <div className="radios_container">
              <p>
                What mode of shipping do you use?{" "}
                <sup className="aesterisk">*</sup>
              </p>
              <div className="radiosButtons">
                <div className="radios">
                  <input
                    type="radio"
                    id="fcl"
                    name="shippingMode"
                    value="Ocean"
                    checked={data.shippingMode === "Ocean" ? "Ocean" : ""}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="fcl">Ocean</label>
                </div>
                <div className="radios">
                  <input
                    type="radio"
                    id="lcl"
                    name="shippingMode"
                    value="Air"
                    checked={data.shippingMode === "Air" ? "Air" : ""}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="lcl">Air</label>
                </div>
                <div className="radios">
                  <input
                    type="radio"
                    id="air"
                    name="shippingMode"
                    value="Both"
                    checked={data.shippingMode === "Both" ? "Both" : ""}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="air"> Both Ocean & Air</label>
                </div>
                <div className="radios">
                  <input
                    type="radio"
                    id="warehousing"
                    name="shippingMode"
                    value="Warehousing"
                    checked={data.shippingMode === "Warehousing" ? "Warehousing" : ""}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="warehousing">Warehousing</label>
                </div>
              </div>
            </div>

            <div className="radios_container shipToUs">
              <p>
                Where do you ship from?
                <sup className="aesterisk">*</sup>
              </p>
              <div className="radiosButtons">
                <div className="radios">
                  <input
                    type="radio"
                    id="ship_india"
                    name="shipFrom"
                    checked={data.shipFrom === "India" ? "India" : ""}
                    value="India"
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="ship_india">India</label>
                </div>
                <div className="radios">
                  <input
                    type="radio"
                    id="ship_uk"
                    name="shipFrom"
                    checked={data.shipFrom === "UK" ? "UK" : ""}
                    value="UK"
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="ship_uk">UK</label>
                </div>
              </div>
            </div>

            <div className="radios_container shipToUs">
              <p>
                Do you ship to the US?
                <sup className="aesterisk">*</sup>
              </p>
              <div className="radiosButtons">
                <div className="radios">
                  <input
                    type="radio"
                    id="ship_yes"
                    name="shipToUs"
                    checked={data.shipToUs === "Yes" ? "Yes" : ""}
                    value="Yes"
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="ship_yes">Yes</label>
                </div>
                <div className="radios">
                  <input
                    type="radio"
                    id="ship_no"
                    name="shipToUs"
                    checked={data.shipToUs === "No" ? "No" : ""}
                    value="No"
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                  <label htmlFor="ship_no">No</label>
                </div>
              </div>
            </div>

            {data.shipToUs === "Yes" && (
              <div className="radios_container shipToUs">
                <p>Do you sell via Amazon Global Selling?</p>
                <div className="radiosButtons">
                  <div className="radios">
                    <input
                      type="radio"
                      id="sell_Yes"
                      name="sellViaAmazonGlobalSelling"
                      checked={
                        data.sellViaAmazonGlobalSelling === "Yes"
                          ? "checked"
                          : ""
                      }
                      value={data.Do_you_sellViaAmazonGlobalSelling}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.id.split("_")[1],
                        });
                      }}
                    />
                    <label htmlFor="sell_Yes">Yes</label>
                  </div>
                  <div className="radios">
                    <input
                      type="radio"
                      id="sell_No"
                      name="sellViaAmazonGlobalSelling"
                      checked={
                        data.sellViaAmazonGlobalSelling === "No"
                          ? "checked"
                          : ""
                      }
                      value={data.Do_you_sellViaAmazonGlobalSelling}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.id.split("_")[1],
                        });
                      }}
                    />
                    <label htmlFor="sell_No">No</label>
                  </div>
                </div>
              </div>
            )}

            {data.shipToUs === "Yes" &&
              data.sellViaAmazonGlobalSelling === "Yes" && (
                <div className="radios_container shipToUs">
                  <p>Amazon storefront link: </p>

                  <input
                    className="InputurlLink"
                    type="text"
                    placeholder="example.com"
                    style={{ width: "20rem !important" }}
                    name="amazonStorefrontLink"
                    value={data.amazonStorefrontLink}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value });
                    }}
                  />
                </div>
              )}

            {(data.shipToUs === "No" ||
              data.sellViaAmazonGlobalSelling === "No") && (
              <div className="radios_container shipToUs">
                <p>Company Website URL: </p>

                <input
                  className="InputurlLink"
                  type="text"
                  placeholder="example.com"
                  style={{ width: "20rem !important" }}
                  name="companyWebsiteURL"
                  value={data.companyWebsiteURL}
                  onChange={(e) => {
                    setData({ ...data, [e.target.name]: e.target.value });
                  }}
                />
              </div>
            )}

            {data.shipToUs === "Yes" && (
              <div className="radios_container shipToUs">
                <p>Do you sell via Walmart Cross Border Trade?</p>
                <div className="radiosButtons">
                  <div className="radios">
                    <input
                      type="radio"
                      id="sellwalmart_Yes"
                      name="sellViaWalmartCrossBorderTrade"
                      checked={
                        data.sellViaWalmartCrossBorderTrade === "Yes"
                          ? "checked"
                          : ""
                      }
                      value={data.Do_you_sellViaWallmartCossBorderTrade}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.id.split("_")[1],
                        });
                      }}
                    />
                    <label htmlFor="sellwalmart_Yes">Yes</label>
                  </div>
                  <div className="radios">
                    <input
                      type="radio"
                      id="sellwalmart_No"
                      name="sellViaWalmartCrossBorderTrade"
                      checked={
                        data.sellViaWalmartCrossBorderTrade === "No"
                          ? "checked"
                          : ""
                      }
                      value={data.Do_you_sellViaWallmartCossBorderTrade}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.id.split("_")[1],
                        });
                      }}
                    />
                    <label htmlFor="sellwalmart_No">No</label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="btnConatiner">
            <input
              onClick={submitForm}
              className="Submitbtn"
              type="button"
              value="Submit"
            />
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        role="alert"
      />
    </div>
  );
}

export default TalkToExpert;
