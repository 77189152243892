import React, { useState } from 'react'
import './Navbar.css'
import Sidebar from '../sidebar/Sidebar'
import HeaderLogo from '../../assests/xhipment-logo.png'
import { AiOutlineMenu } from 'react-icons/ai'
import { ProvContext } from '../../context/Context'
import mixpanel from "mixpanel-browser"
import { motion, useScroll, useSpring } from "framer-motion";
mixpanel.init(`${process.env.REACT_APP_MIXPANELID}`, {
    debug: true,
    ignore_dnt: true

})



function Navbar() {
    const [navbar, setNavbar] = useState(false)
    const { setCloseSidebar, mixpanelId } = ProvContext()
    const changeBackground = () => {
        if (window.scrollY >= 90) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    let logInBtnClick = (e) => {
        mixpanel.track("# Visits to login page", {
        })
    }

    const { scrollYProgress } = useScroll();
    
const scaleX = useSpring(scrollYProgress, {
  stiffness: 100,
  damping: 30,
  restDelta: 0.001
});

    window.addEventListener('scroll', changeBackground)
    return (
        <header className={navbar ? "navbar-wrapper active" : "navbarhome-wrapper "}>
            <div className="container container-fluid-lg">
                <div className="navbarhome-containers">
                    <div className="logo-container">
                        <div className="header-logo-area">
                            <a href="#header">
                                <img className="logo-light" src={HeaderLogo} alt="Logo" width="161" height="48" />
                            </a>
                        </div>
                    </div>
                    <div className="navigation-container d-none d-lg-block">
                        <div className="headerhome-navigation-area">
                            <ul className="main-menu nav position-relative gap-5 ">
                                <li ><a className={navbar ? "navigationhome-anchors mactive" : "navigationhome-anchors"} href="#aboutus">About Us</a></li>
                                <li><a className={navbar ? "navigationhome-anchors mactive" : "navigationhome-anchors"} href="#services">Services</a></li>
                                <li><a className={navbar ? "navigationhome-anchors mactive" : "navigationhome-anchors"} href="#faq">FAQs</a></li>
                                <li><a className={navbar ? "navigationhome-anchors mactive" : "navigationhome-anchors"} href="#signup">Sign Up</a></li>
                                <li><a className={navbar ? "navigationhome-anchors mactive" : "navigationhome-anchors"} href="#tracking"  id="#tracking">Track Your Xhipment</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="login-container">
                        <div className="header-action-area">
                            <div className="header-action-btn" >
                                <button id="btnSignup" className="btn-theme d-none">Sign&nbsp;Up</button>
                            </div>

                            <div className="header-action-btn">
                                <a rel="noreferrer" href={`${process.env.REACT_APP_SITE_URL}/?dstinct_id=${mixpanelId}`} target='_blank' id="btnRedirect" onClick={(e) => { logInBtnClick(e) }} className={navbar ? "btn-theme active" : "btn-theme "}> Log In</a>
                            </div>
                            <button className={navbar ? "btn-menu d-lg-none d-block active" : "btn-menu d-lg-none d-block"} onClick={() => { setCloseSidebar(true) }} type="button" >
                                <AiOutlineMenu className='menubar' />
                            </button>
                            <div className='d-lg-none d-block '>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    <motion.div className="progress-bar" style={{ scaleX }} />

        </header >
    )
}

export default Navbar;
