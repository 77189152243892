import React, { useState, useEffect } from "react";
import "./index.css";
import HeaderLogo from "../../assests/xhipment-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { RxDoubleArrowUp } from "react-icons/rx";
import Footer from "../../Footer/Footer";
function Terms() {
  const [navbar, setNavbar] = useState(false);
  const [scrollBtn, setScrollbtn] = useState(false);
  const navigate = useNavigate();
  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    if (window.location.hash === `#quote`) {
      window.location.href = "#quote";
    } else if (window.location.hash === `#accessorial`) {
      window.location.href = "#accessorial";
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const navigateToTop = () => {
    if (window.scrollY >= 230) {
      setScrollbtn(true);
    } else {
      setScrollbtn(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  window.addEventListener("scroll", navigateToTop);
  return (
    <div id="Terms" className="  term_container ">
      <header className={navbar ? "navbar-wrapper active" : "navbar-wrapper "}>
        <div className="container container-fluid-lg">
          <div className="navbar-container">
            <div className="logo-containers">
              <div className="header-logo-area">
                <a
                  href="#header"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    className="logo-light"
                    src={HeaderLogo}
                    alt="Logo"
                    width="161"
                    height="48"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {scrollBtn && (
        <div className="scrollContainer">
          <a style={{ color: "white" }} href="#Terms">
            {" "}
            <RxDoubleArrowUp />
          </a>
        </div>
      )}
      <div className="terms_body container px-md-5 px-3 d-flex flex-column gap-3">
        <div className="term-heading">
          <h6>
            LAST UPDATED DATE : 15<sup>th</sup> Feb 2024
          </h6>
        </div>
        <div className="term-heading">
          <h2>Terms & Conditions</h2>
          <h6 className="hp1">
            THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION
            TECHNOLOGY ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE
            AMENDED PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS
            STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS
            ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT
            REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
          </h6>

          <p className="hp2">
            Welcome to the Terms of Use (“Terms”) of Holaport Logistics Private
            Limited, a company incorporated under the laws of India with its
            registered office at 91 Springboard Business Hub Pvt Ltd, B1/H3,
            Mohan Co-operative, Mathura Rd, Industrial Area, Block B, New Delhi,
            Delhi 110044 (“Company”, “We”, “Us”, or “Our”). The Company is
            engaged in the business of facilitating freight forwarding for B2B
            companies in various jurisdictions(“Service”).
          </p>

          <p className="hp3">
            {" "}
            By accessing or using the website available at www.xhipment.com and
            any subdomains (“Platform”), You agree to be bound by these Terms.
          </p>

          <p>
            Your agreement with Us includes these Terms and the Privacy Policy
            available on the Platform (collectively “Agreement”).
          </p>
          <p>
            The expressions “You” or “Your” or “User” refers to any person who
            downloads, accesses or uses the Platform for any purpose.
          </p>
          <p>
            All terms used with a capitalized first letter shall have the
            meaning assigned to them in these Terms or the Privacy Policy.
          </p>
        </div>

        <div className="Ammendent points">
          <h6>AMENDMENT TO THE TERMS</h6>
          <p>
            If You do not agree with these Terms or any provisions in the
            Agreement, then refrain from using the Platform. We reserve the
            right to change, modify, amend, or update these Terms and/or the
            Agreement from time to time and such amended provisions of these
            Terms and/or the Agreement shall be effective immediately upon being
            posted on the Platform. You can determine when these Terms and/or
            Agreement were last revised by referring to the ‘LAST UPDATED DATE’
            at the top of these Terms and/or Agreement. Your continued use of
            the Platform shall be deemed to signify Your acceptance of these
            provisions or amended provisions of these Terms and/or the
            Agreement.
          </p>
        </div>
        <div className="Eligibility points">
          <h6>ELIGIBILITY CRITERIA</h6>
          <p>
            You represent and warrant that You have the right to access or use
            the Platform. The Platform can only be availed by those individuals
            or business entities, including sole proprietorship firms, companies
            and partnerships, which can form legally binding contracts under
            Indian Contract Act, 1872. Persons who are “incompetent to contract”
            within the meaning of the Indian Contract Act, 1872 including
            minors, un-discharged insolvents etc. are not eligible to use the
            Platform.
            <br />
            <br />
            If You are a minor that is any person under the age of 18 (eighteen)
            years, who is responsible for or operating or functioning any
            company, establishment, sole proprietorship or any such business,
            You may use the Platform only if You have been specifically
            authorized by the relevant organisation.
            <br />
            <br />
            Any person or User accessing or registering on behalf of an
            organization or business must be an authorized representative and
            acceptance of the terms by any such person or User is deemed
            acceptance by the organisation or business such person or User
            represents.
          </p>
        </div>
        <div className="General points">
          <h6>GENERAL DISCLAIMER</h6>
          <p>
            By using the Platform, You agree that You meet the Eligibility
            Criteria as detailed in Clause 2 (above) and that You are fully able
            and competent to understand and accept this Agreement as a binding
            contract and to abide by these Terms.
          </p>
          <p>
            You agree that You shall not access the Platform by any other means
            other than through the interfaces that are provided by Us
          </p>
          <p>
            You agree that You shall not acquire, copy, or monitor any portion
            of the Platform in any way to reproduce or circumvent the
            navigational structure or presentation of the Platform, to obtain or
            attempt to obtain any materials, documents, or information through
            any means not specifically made available through the Platform.
          </p>
          <p>
            You agree that You shall not attempt to reverse engineer,
            de-encrypt, or otherwise derive the design, internal logic,
            structure or inner workings (including algorithms and source code)
            of the Platform, any other software, products, models, prototypes,
            or other items provided by Us.
          </p>
          <p>
            You agree that You shall not use any deep link, robot, spider or
            other automatic device or methodology, or any similar or equivalent
            manual process, to access, acquire, download, copy or monitor the
            Platform or any part thereof, or obtain any content through means
            not specifically made available through the Platform
          </p>
          <p>
            You agree that You shall not breach any provisions of the Agreement
            or violate any provisions of applicable law.
          </p>
        </div>

        <div className="REGISTRATION">
          <h6>REGISTRATION PROCESS</h6>
          <p>
            You may register on the Platform as by providing information such as
            but not limited to Company name, email address, name etc and any
            other details as requested by the Company. The Company shall on the
            User providing such information create an account and provide access
            the User access to the same.
            <br />
            <br />
            Upon registration, You shall be responsible for maintaining
            confidentiality of the password and the account including Your
            personal details. You shall be responsible for ensuring that all
            such information/ details are always up to date on the Platform. You
            agree that if You provide any information that is untrue,
            inaccurate, not updated or incomplete or in case We have reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            updated, incomplete, or not in accordance with these Terms or the
            account has been misused, We shall have the right to indefinitely
            suspend or terminate or block Your access rights immediately without
            notice and block user access from that IP address.
          </p>
        </div>

        <div className="Termination">
          <h6>TERMINATION</h6>
          <p style={{ fontWeight: "500", fontSize: "16.5px" }}>
            Company may suspend or terminate Your use of the Platform:
          </p>
          <ul>
            <li>
              If it believes, in its sole and absolute discretion that the User
              has breached, violated, abused, or unethically manipulated or
              exploited any term of the Agreement;{" "}
            </li>
            <li>
              If Company has reasonable grounds to suspect that any information
              provided is untrue, inaccurate, not current or incomplete, or not
              in accordance with these Terms; and
            </li>
            <li>
              If the Company in its sole discretion for any reason deems fit to
              terminate Your use of the Platform.
            </li>
          </ul>
        </div>
        <div className="Personal-info points">
          <h6>USE OF YOUR PERSONAL INFORMATION</h6>
          <p style={{ wordSpacing: "0.08rem" }}>
            In the course of use of the Platform, the User may be asked to
            provide certain personal information to Us. The Company’s
            information collection and use policies with respect to privacy of
            such information are set forth in the Privacy Policy available at{" "}
            <Link to={"/Home/Privacy"}>
              https://www.xhipment.com/Home/Privacy
            </Link>
          </p>
        </div>

        <div className="GUARANTEES points">
          <h6>NO GUARANTEES</h6>
          <p>Among other things, the Company does not guarantee that:</p>
          <ul>
            <li>The Platform will meet the Your expectations; or</li>
            <li>
              The Platform will be accessible without interruption or in a
              timely, reliable, or fault-free manner; or
            </li>
            <li>
              The results obtained through the use of the Platform will be
              correct and reliable
            </li>
          </ul>
          <p>
            No guidance or information, written or oral, obtained from the
            Company or via the Platform, shall constitute any warranty, which
            has not specifically been stated in these Terms.
          </p>
        </div>
        <div className="DISCLAIMER ">
          <h6>DISCLAIMER AND LIMITATION OF LIABILITIES</h6>
          <p>
            THIS PLATFORM IS PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
            OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS
            PLATFORM OR THE INFORMATION, CONTENT INCLUDED ON THIS PLATFORM. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK.
            THE COMPANY RESERVES THE RIGHT TO WITHDRAW OR DELETE ANY INFORMATION
            FROM THIS PLATFORM AT ANY TIME IN ITS DISCRETION.
          </p>
          <p>
            TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, THE COMPANY
            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED. THE COMPANY DOES NOT
            WARRANT THAT THIS PLATFORM, ITS SERVERS, OR EMAIL SENT FROM THE
            COMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE COMPANY
            WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE
            OF THIS PLATFORM, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT,
            INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.
          </p>
        </div>

        <div className="WAIVER points">
          <h6>WAIVER</h6>
          <p>
            No provision of the Agreement shall be deemed to be waived and no
            breach excused, unless such waiver or consent shall be in writing
            and signed by the Company. Any consent by the Company to, or a
            waiver by the Company of any breach by You, whether expressed or
            implied, shall not constitute consent to, waiver of, or excuse for
            any other different or subsequent breach.
          </p>
        </div>

        <div className="GOVERNING points">
          <h6>GOVERNING LAW AND JURISDICTION</h6>
          <p>
            These Terms shall be governed by the laws of India. Subject to the
            dispute resolution clause below, courts and tribunals of Bengaluru,
            India have exclusive jurisdiction to settle any dispute arising out
            of or in connection with these Terms (including any disputes
            regarding the existence, validity or termination of these Terms).
          </p>
        </div>

        <div className="NOTICES points">
          <h6>NOTICES</h6>
          <p>
            All notices or demands to or upon the Company shall be effective if
            in writing and shall be deemed to be duly made when sent to 91
            Springboard Business Hub Pvt Ltd, B1/H3, Mohan Co-operative, Mathura
            Rd, Industrial Area, Block B, New Delhi, Delhi 110044. All notices
            or demands to or upon a User shall be effective if either delivered
            personally, sent by courier, certified mail, by facsimile or email
            to the last-known correspondence, fax or email address provided by
            the You on the Platform, or by posting such notice or demand on an
            area of the Platform that is publicly accessible.
          </p>
          <p>
            Notice to User shall be deemed to be received by such User if and
            when the Platform is able to demonstrate that communication, whether
            in physical or electronic form, has been sent to such User, or
            immediately upon Platform’s posting such notice on an area of the
            Platform that is publicly accessible.
          </p>
        </div>
        <div className="MISCELLANEOUS points">
          <h6>MISCELLANEOUS</h6>
          <p className="Misfirst">
            Headings for any section of the Agreement are for reference purposes
            only and in no way define, limit, construe or describe the scope or
            extent of such section
          </p>
          <p>
            The Company shall have the right to assign its obligations and
            duties in this Agreement to any person or entity.
          </p>
          <p>
            All telephonic calls to the Company will be kept completely
            confidential. However, Your call may be recorded to ensure quality
            of service. Further, for training purposes and to ensure excellent
            customer service, calls from the Company to You or from You to the
            Company may be monitored and recorded.
          </p>
          <p id="quote">
            Bharat Bhardwaj is the designated Grievance Officer in respect of
            the Platform. Any complaints or concerns with regards to any content
            on the Platform or any breach of this Agreement or Privacy Policy
            can be directed to the designated Grievance Officer in writing at 91
            Springboard Business Hub Pvt Ltd, B1/H3, Mohan Co-operative, Mathura
            Rd, Industrial Area, Block B, New Delhi, Delhi 110044 or through an
            email signed with electronic signatures sent to
            support@xhipment.com.
          </p>
        </div>

        <div className="Quotation">
          <h2>Quotation Terms and Conditions</h2>
          <dl>
            <dt>Overview:</dt>
            <dd>
              <ul>
                <li>
                  Holaport Logistics Private Limited, hereby referred to as
                  <span>“Holaport”</span>, operating under the brand name{" "}
                  <span> “Xhipment”</span>, amongst other things through its
                  digital platform, provides the Customer shipment, logistic
                  services, freight forwarding services through its vendors.
                </li>
                <li>
                  Our offer(s) do not include the insurance. Please contact us
                  for more details.
                </li>
                <li>
                  Please note that the transit time indicated next to Port-Port
                  Information corresponds to the transit time between the two
                  ports and will be revised as updated from time to time. All
                  transit times provided are based on information available to
                  Holaport at the time of providing the quote and are subject to
                  change.
                </li>
                <li>
                  In addition to these terms and conditions, Holaport’s digital
                  platform terms and conditions and any other specific Customer
                  terms (which shall be shared with the Customer) shall be
                  applicable. and it shall be further construed that on
                  acceptance of the Quotation, these terms will be deemed to
                  have been read over and duly accepted by the you, the
                  Customer.
                </li>
                <li>
                  Unless guaranteed service is expressly stated by Holaport, all
                  services are provided with reasonable dispatch, and Holaport
                  do not guarantee that services will be delivered without delay
                  nor shall Holaport assume liability for penalties, fines,
                  chargebacks, or any other costs associated with delayed
                  services.
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>Definitions:</dt>
            <dd>
              <ul>
                <li>
                  <span>"Customer"</span>shall mean any person who avails the
                  Services.
                </li>

                <li>
                  <span>"Services"</span> shall mean the services as more
                  specifically listed on the Quotation, including without
                  limitation shipping and logistic services, associated services
                  including freight insurance or customs brokerage services,
                  trade finance and invoice discounting as may be agreed to in
                  the Quotation.
                </li>
                <li>
                  <span>“Quotation”</span> means the quotation shared with you
                  by Holaport on the digital platform.
                </li>
              </ul>
            </dd>
          </dl>

          <dl>
            <dt>Terms and Conditions:</dt>
            <dd>
              <ul className="termsAndConditions">
                <li>
                  <span>Confidentiality:</span> This offer, and quotation shall
                  be the exclusive property of Holaport, and may not be
                  reproduced or distributed in any way or used by the Customer
                  except with the express prior written consent of Holaport. The
                  Customer undertakes to keep this offer and quotation and any
                  information provided by Holaport strictly confidential and
                  shall not disclose the same to any third party.
                </li>
                <li>
                  <span>Insurance</span>: Unless requested to do so in writing,
                  and confirmed to Customer in writing, Holaport is under no
                  obligation to procure insurance on Customer&#39;s behalf. In
                  all cases, Customers shall pay all premiums and costs in
                  connection with procuring necessary insurance.
                </li>
                <li>
                  <span>Payments</span>:
                  <ol className="romanPoints termsCondition">
                    <li>
                      All payments are due and payable without offset within
                      fifteen (15) days from the invoice date, including
                      Saturdays, Sundays, and legal holidays. The day after the
                      invoice date begins the credit term. Invoices not paid
                      within fifteen (15) days from the invoice date or other
                      credit period, if applicable, and for which Holaport
                      utilizes an outside collection agency and/or attorney to
                      effect collections will be subject to a collections
                      handling fee of thirty percent (30%) of the unpaid amount.
                      In addition to the foregoing, payments that are made sixty
                      days (60) after the invoice date are subject to a ten
                      percent (10%) liquidated damage charge based on the total
                      freight charges of the specific past-due invoice.
                    </li>

                    <li>
                      The Customer shall not have any right of set-off
                      whatsoever and all invoices raised by Holaport shall be
                      payable in full and without any deduction or setoff
                      whatsoever, other than the deduction of TDS subject to
                      submission of the receipts in a timely manner. Statutory
                      charges if and when applicable, such as Duty, Taxes, GST
                      are payable in full and in advance.
                    </li>

                    <li>
                      Rates are quoted in the currency Holaport selected or
                      customer requested, based on the rate of exchange
                      prevailing at the time of submission or mutual agreed.
                      Should the rate of exchange differ from agreed rate of
                      exchange at the time of invoicing, Holaport reserves the
                      right to adjust either the rates or the rate of exchange.
                      All invoices and billing statements are subject to
                      issuance in local currency and local legislation.
                    </li>
                    <li>
                      The Customer understands that the invoice may be raised by
                      Holaport or its US entity First Shipment Inc as decided by
                      Holaport at its discretion.
                    </li>
                  </ol>
                </li>

                <li>
                  <span>Rates:</span>
                  <ol className="romanPoints termsCondition">
                    <li>
                      The Air Freight calculation is based on weight or volume
                      rate 1:6, whichever may be higher
                    </li>
                    <li>
                      Ocean Freight calculation is based on a weight or volume
                      rate of 1:1, whichever may be higher. Ocean freight rates
                      and surcharges are subject to change at any time.
                    </li>

                    <li>
                      Trucking tariffs are based on assumption that 1 CBM = 333
                      kg, and max. length of 270cm. /max. width 180cm./ max.
                      height 150cm max. weight per pc of 500 kg unless otherwise
                      specified.
                    </li>

                    <li>
                      All ocean rates will be subject to General Rate Increase
                      (GRI), Peak Season Surcharge (PSS), and any additional
                      surcharge type that might be imposed by the carriers. Due
                      to current industry conditions and capacity constraints,
                      pricing is subject to continual change, and Holaport
                      reserves the right to charge capacity surcharges and
                      increase rates. For regulated lanes, such rates will be
                      pursuant to the applicable published tariff(s).
                    </li>

                    <li>
                      Rates are services including, but not limited to, inbound
                      collect surcharge, special handling, residential delivery
                      services surcharge, detention, demurrage, fumigation,
                      insurance, storage after free time, customs bond, duties,
                      VAT, taxes, user fees, courier and any other
                      government-mandated fees are excluded from the quote
                      unless otherwise specified
                    </li>

                    <li>
                      Rates apply to dry &amp; non-hazardous general cargo,
                      excluding dangerous goods, perishables, valuables,
                      oversized cargo, or parcel cargo unless specified
                      otherwise. Hazardous cargo is subject to shipping line
                      acceptance at the time of booking
                    </li>
                    <li >
                      Rates apply only for in-gauge cargo of legal weight and
                      dimensions which may vary as per country
                    </li>

                    <li id="accessorial">
                      Rates or services not specified in this quotation will be
                      billed to the customer as receipted
                    </li>

                    <li >
                      Customs inspection charges will be applicable if the
                      package is opened for examination &amp; repacked as per
                      orders by a customs authority
                    </li>

                    <li >
                      Brokerage accessorial fees may apply if entry
                      characteristics exceed the routine number of lines of
                      classification, commercial invoices, Bills of Lading, or
                      if any additional government agency filings are required.
                    </li>

                    <li >
                      Outlays for duties, taxes, or freight paid by Holaport on
                      the Customer&#39;s behalf are subject to a disbursement
                      fee of 1% (one percent) of the total outstanding amount
                    </li>
                  </ol>
                </li>

                <li >
                  <span >Accessorials - Air:</span>
                  <p>
                    The policy outlines the procedures for handling accessorial
                    charges in cases where customer acknowledgment is expected
                    to be received within 3 working days(excluding weekends).
                    We're working to make our services smoother and more
                    transparent, reducing surprises and ensuring you have a
                    clear understanding of any additional charges.
                  </p>
                  <ol className="termsCondition romanPoints">
                    <li>
                      <span> Definition of Accessorial Charges</span>
                      <p>
                        Accessorial charges encompass additional fees or charges
                        incurred during the transportation process beyond
                        standard freight charges. Examples but not limited to
                        include detention fees, storage charges, rerouting fees,
                        and similar charges. The exhaustive list is mentioned in
                        Annexure 1
                      </p>
                    </li>
                    <li>
                      <span>Customer Acknowledgment Required</span>
                      <p>
                        Upon the assessment of accessorial charges, customers
                        will be promptly notified through electronic
                        communication (email, online portal, or other approved
                        channels). It is mandatory for customers to acknowledge
                        and approve or dispute these charges within 3 working
                        days (excluding weekends) of receiving the notification.
                        All necessary proof of deliveries (POD) will be provided
                        to facilitate the resolution process.
                      </p>
                    </li>

                    <li>
                      <span>Notification of Confirmed Accessorial Charges</span>
                      <p>
                        In case we do not receive your acknowledgement within 3
                        working days, we'll send another notification. This
                        notification will inform you that accessorial charges
                        are added to your invoice with a summary, reason, and
                        supporting docs provided as supporting information. We
                        will send daily emails, WhatsApp messages, and if
                        necessary, make calls to ensure we have your explicit
                        approval during the first 3 working days.
                      </p>
                    </li>
                  </ol>
                  <p>
                    <span>Annexure 1 -</span> Additional charges beyond those
                    stated FYR will be communicated for approval.
                  </p>
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Final Name</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Invoice Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Return fee applicable if the shipment needs to be
                          returned back to the seller
                        </td>
                        <td>Per Shipment</td>
                        <td>
                          This charge is  applied if the shipment needed to be
                          returned to the origin due to unforeseen
                          circumstances. It covers the expenses incurred in the
                          process of returning the cargo to the origin, ensuring
                          a smooth and managed return of goods due to unexpected
                          events or situations.
                        </td>
                      </tr>
                      <tr>
                        <td>Shipping Bill Charges</td>
                        <td>Per Invoice</td>
                        <td>
                          This fee is applied when your shipping bill needs to
                          be refiled after expiration (typically within 3 days).
                          It covers the costs associated with the re-submission
                          or renewal of the shipping bill to ensure compliance
                          and proper documentation, allowing for the
                          continuation of the shipping process within the
                          specified timeframe.
                        </td>
                      </tr>
                      <tr>
                        <td>TC Amendment Charge</td>
                        <td>Per Invoice</td>
                        <td>
                          This fee is applied when amendments were made to the
                          Terminal Challan (TC). It covers the costs related to
                          modifying or updating the TC, ensuring accuracy and
                          compliance with revised information pertaining to the
                          shipment or logistics processes
                        </td>
                      </tr>
                      <tr>
                        <td>Carton Labelling Cost</td>
                        <td>Per Carton</td>
                        <td>This charge is applied when cargo requires re-labelling. It covers the expenses involved in the process of re-labelling cartons, ensuring accurate and compliant labelling of goods for shipment or regulatory purposes.</td>
                      </tr>
                      <tr>
                        <td>AD code Registration Charge</td>
                        <td>Per Shipper</td>
                        <td>This fee is applied for registering the AD Code. It covers the costs associated with the registration process, ensuring compliance with necessary AD Code requirements for the customer's shipments.</td>
                      </tr>
                      <tr>
                        <td>IFSC Registration Charge</td>
                        <td>Per Shipper</td>
                        <td>This fee is applied when registering with IFSC (Indian Financial System Code). It covers the costs involved in the registration process, ensuring the necessary documentation and compliance with IFSC requirements for the shipper's transactions.</td>
                      </tr>
                      <tr>
                        <td>Origin Palletization</td>
                        <td>Per Pallet</td>
                        <td>This fee is applied when cargo underwent palletization at its origin based on your instructions. It covers the costs associated with the process of arranging and securing goods onto pallets for efficient handling and transport from the starting point of the shipment.</td>
                      </tr>
                      <tr>
                        <td>Phytosanitary Certification</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied when obtaining a certificate for cargo as it contains Plants/Plant Products/other regulated articles. It covers the costs involved in securing this essential certificate, ensuring compliance with regulations regarding agricultural products. This certification guarantees the health and safety of plant-based items during shipment.</td>
                      </tr>
                      <tr>
                        <td>A&Q Certificate</td>
                        <td>Per Shipment</td>
                        <td>This fee is incurred when obtaining a certificate for cargo as it contains Animal/Animal Products/other regulated articles. It covers the expenses related to securing this vital certificate, ensuring compliance with regulations concerning animal-based or related items. This certification guarantees the adherence to health and safety standards during the shipment of such products.</td>
                      </tr>
                      <tr>
                        <td>Rubber stamp Charges</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied as you couldn't provide original copies of non-DG (non-dangerous goods) certificates. It covers the cost for authorized stamping of duplicate or substitute documents, ensuring compliance with shipping regulations when original certifications were unavailable</td>
                      </tr>
                      <tr>
                        <td>Package Handling Charges</td>
                        <td>Per Kg</td>
                        <td>This fee is applied when picking up overweight cargo exceeding 32 kilograms. It covered the additional handling costs incurred due to the heavier weight, ensuring safe and proper handling during collection to accommodate the increased load</td>
                      </tr>
                      <tr>
                        <td>Re-attempt Charge</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied when cargo requires redelivery due to unsuccessful initial delivery attempts. It covered the costs associated with the subsequent delivery attempts, ensuring your shipment reaches its destination after initial unsuccessful delivery.</td>
                      </tr>
                      <tr>
                        <td>Sunday Holiday Delivery</td>
                        <td>Per LR</td>
                        <td>This fee is incurred when delivery services are required for a Sunday, typically a non-working day. It covered the additional costs associated with arranging and executing the delivery on a Sunday, ensuring the timely arrival of your shipment despite it being on a day outside regular working hours.</td>
                      </tr>
                      <tr>
                        <td>Demurrage Charge</td>
                        <td>Per Kg</td>
                        <td>This fee is applied when cargo remains in the warehouse for more than 7 days beyond the agreed-upon delivery period. It covered the additional costs incurred due to the extended storage of goods, encouraging timely pickup or delivery to avoid prolonged occupancy in the warehouse.</td>
                      </tr>
                      <tr>
                        <td>Wildlife NOC Charge (to be added in Amazon contract)</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied when cargo requires a 'No Objection Certificate' (NOC) from the Wildlife Crime Control Bureau. It covered the expenses associated with obtaining the necessary certification for shipments containing wildlife-related items, ensuring compliance with regulations and ethical standards set by the bureau</td>
                      </tr>
                      <tr>
                        <td>Single Entry Bond Charges</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied when procuring a single entry bond for you. It covered the costs related to obtaining this specific type of bond, which ensures compliance and financial responsibility for a single import or export transaction, providing necessary assurance to customs authorities</td>
                      </tr>
                      <tr>
                        <td>Annual Bond</td>
                        <td>Per Shipment</td>
                        <td>This fee was applied when obtaining a continuous entry bond for you on an annual basis. It covered the costs associated with securing this bond, which offers ongoing coverage for multiple import or export transactions throughout the year, ensuring compliance and financial responsibility with customs regulations</td>
                      </tr>
                      <tr>
                        <td>IOR Charges</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied when procuring an Importer of Record (IOR). It covered the expenses related to appointing an authorized entity or individual responsible for ensuring compliance with customs regulations and facilitating the importation process on behalf of you.</td>
                      </tr>
                      <tr>
                        <td>Detention Charges</td>
                        <td>Per Hour</td>
                        <td>This fee is imposed when cargo remained uncleared from the airport beyond the free days provided by the airline. It covered the costs incurred due to the extended storage of goods at the airport premises.</td>
                      </tr>
                      <tr>
                        <td>Insurance Charges</td>
                        <td>Per Shipment</td>
                        <td>This fee is imposed as freight insurance for your shipment upon your request.</td>
                      </tr>
                      <tr>
                        <td>Urgent Clearance Charges</td>
                        <td>Per Shipment</td>
                        <td>This fee is applied as your cargo needed clearance in a very short time frame</td>
                      </tr>
                      <tr>
                        <td>Sorting Charges</td>
                        <td>Per Pallet</td>
                        <td>This charge is levied as your cargo needed rearrangement of boxes to be delivered to the destination without delays</td>
                      </tr>
                      <tr>
                        <td>Storage Charges (warehouse)</td>
                        <td>Per Shipment</td>
                        <td>This charge is levied as your cargo was stored a our facility</td>
                      </tr>
                      <tr>
                        <td>Storage Charges (bonded warehouse)</td>
                        <td>Per Shipment</td>
                        <td>This fee is imposed as your freight needed additional examination and could not be custom cleared on the same day</td>
                      </tr>
                      <tr>
                        <td>Storage Charges (Destination delivery warehouse)</td>
                        <td>Per Day</td>
                        <td>This fee is levied as your cargo was picked up early by the destination delivery partner and needed to be delivered the next day</td>
                      </tr>
                      <tr>
                        <td>PGA(FDA+) Release Charge</td>
                        <td>Per Shipment</td>
                        <td>Partner government agency that works alongside the Customs and Border Protection (CBP) that regulate commodities entering the U.S.</td>
                      </tr>
                      <tr>
                        <td>Repackaging Cost for required for Exports</td>
                        <td>Per Carton</td>
                        <td>In case - the carton is damaged or not well packed as per Amazon compliance</td>
                      </tr>
                    </tbody>
                  </table>
                </li>

                <li>
                  <span>Accessorials - Ocean:</span>
                  <p>
                    <span>Policy around Accessorials -</span>
                    The policy outlines the procedures for handling accessorial charges in cases where customer acknowledgment is expected to be received within 3 working days. We're working to make our services smoother and more transparent, reducing surprises and ensuring you have a clear understanding of any additional charges.
                  </p>
                  <ol className="termsCondition romanPoints">
                    <li>
                      <span> Definition of Accessorial Charges:</span>
                      <p>
                        Accessorial charges encompass additional fees or charges incurred during the transportation process beyond standard freight charges. Examples but not limited to include detention fees, storage charges, rerouting fees, and similar charges . The exhaustive list is mentioned in Annexure 1
                      </p>
                    </li>
                    <li>
                      <span>Customer Acknowledgment Required :</span>
                      <p>
                        Upon the assessment of accessorial charges, customers will be promptly notified through electronic communication (email, online portal, or other approved channels). It is mandatory for customers to acknowledge and approve or dispute these charges within 3 working days (excluding weekends) of receiving the notification. All necessary proof of deliveries (POD) will be provided to facilitate the resolution process.
                      </p>
                    </li>

                    <li>
                      <span>Notification of Confirmed Accessorial Charges :</span>
                      <p>
                        In case we do not receive your acknowledgement within 3 working days, we'll send another notification. This notification will inform you that accessorial charges are added to your invoice with a summary, reason, and supporting docs provided as supporting information. We will send daily emails, WhatsApp messages, and if necessary, make calls to ensure we have your explicit approval during the first 3 working days.
                      </p>
                    </li>
                  </ol>
                  <p>
                    <span>Annexure 1 -</span> Additional charges beyond those stated FYR will be communicated for approval.
                  </p>
                  <table class="table table-bordered oceanTable">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Charge Item</th>
                        <th scope="col">Leg</th>
                        <th scope="col">When will it be applicable</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Additional Clearance Charge</td>
                        <td>Origin</td>
                        <td>Applicable when an invoice has more than 50 line items</td>
                      </tr>
                      <tr>
                        <td>Warai</td>
                        <td>Origin</td>
                        <td>Local union charges applicable on all CFS deliveries</td>
                      </tr>
                      <tr>
                        <td>DC Supervision</td>
                        <td>Origin</td>
                        <td>Examination by local customs officer. CHA will inform whether this is applicable on a shipment or not. Usually applied when there is a document, label or carton number mismatch</td>
                      </tr>
                      <tr>
                        <td>Additional Shipping Bill Charges</td>
                        <td>Origin</td>
                        <td>When more than 1 shipping bill is required by the shipper</td>
                      </tr>
                      <tr>
                        <td>Container Rollover</td>
                        <td>Origin</td>
                        <td>Applicable when we aren’t able to gate-in a container basis cut off and miss sailing</td>
                      </tr>
                      <tr>
                        <td>Prepull</td>
                        <td>Destination</td>
                        <td>When a container needs to pulled out a day before delivery</td>
                      </tr>
                      <tr>
                        <td>Chassis</td>
                        <td>Destination</td>
                        <td>The 2 day chassis is part of the base quote. Anything above is accessorial.</td>
                      </tr>
                      <tr>
                        <td>Storage</td>
                        <td>Destination</td>
                        <td>Container requires storage at the yard</td>
                      </tr>
                      <tr>
                        <td>Amazon Detention</td>
                        <td>Destination</td>
                        <td>Applicable when unloading time by Amazon exceeds free unloading time offered by the carrier</td>
                      </tr>
                      <tr>
                        <td>Redelivery</td>
                        <td>Destination</td>
                        <td>If carrier needs to revisit the location to deliver the cargo after the first attempt</td>
                      </tr>
                      <tr>
                        <td>Drop Load</td>
                        <td>Destination</td>
                        <td>When a container needs to be dropped at the location for delivery. Carrier has to come back to collect the container leading to doubling of the cost</td>
                      </tr>
                      <tr>
                        <td>Weekend Delivery</td>
                        <td>Destination</td>
                        <td>Charges for delivery on a Saturday/Sunday</td>
                      </tr>
                      <tr>
                        <td>Liner Detention</td>
                        <td>Destination</td>
                        <td>Applicable when container is returned after return Last Free Day (LFD)</td>
                      </tr>
                      <tr>
                        <td>Port Demurrage</td>
                        <td>Destination</td>
                        <td>If container isn’t picked up with pick up LFD after discharge</td>
                      </tr>
                    </tbody>
                  </table>
                </li>

                <li>
                  <span>Dangerous Goods:</span>
                  <ol className="romanPoints termsCondition">
                    <li>
                      The Customer shall not ship any goods which are generally
                      classified as dangerous goods including but not limited to
                      any goods termed as dangerous goods as per law or
                      regulation of the port of shipment, port of delivery or
                      any intermediate port. In case the Customer ships any
                      dangerous goods the Customer shall be solely liable for
                      any and all damages, costs, fines that may arise due to
                      such goods.
                    </li>
                  </ol>
                </li>

                <li>
                  {" "}
                  <span>Custom Duty, Detention and Demurrage Charges:</span>
                  <ol className="romanPoints termsCondition">
                    <li>
                      All charges such as but not limited to Custom duty,
                      demurrage, port congestion fees, layover fees, pre-pull
                      fees and detention charges will be charged at actuals and
                      will be borne by the Customer without any deviation.
                    </li>
                    <li>
                      Custom Duty, demurrage charges must be paid by Customer in
                      advance, unless Holaport agrees in writing to extend
                      credit to the Customer. The granting of credit to a
                      Customer in connection with a particular transaction shall
                      not be considered a waiver of this provision by Holaport.
                    </li>
                  </ol>
                </li>
                <li>
                  {" "}
                  <span>Packaging:</span>
                  <ol className="romanPoints termsCondition">
                    <li>
                      All shipments must be packaged in cartons, crates, or
                      other packaging suitable for transportation or handling by
                      in accordance with industry standards. The Customer shall
                      be solely responsible for the contents, packaging, and
                      labeling of the shipments as per applicable regulations
                      and the terms and conditions of the selected
                      carrier. Cargo, which is insufficiently prepared, packed
                      or protected will be, at the Customer/consignee sole risk
                      and expense, including risk to third parties and subject
                      to the terms and conditions of the bill of lading. All of
                      Holaport’s rights and immunities in the event of loss or
                      damage by reason of that insufficient preparation,
                      packaging or protection are hereby expressly reserved.
                      <br />
                      The Customer will not use any vendor's including Amazon's
                      packaging or logo or brand in any way to misrepresent or
                      perceive that the shipment is of such vendor or is vetted
                      or verified by such vendor.
                    </li>
                  </ol>
                </li>
                <li>
                  {" "}
                  <span>
                    General Lien and Right To Sell Customer's Property
                  </span>
                  :
                  <ol className="romanPoints">
                    <li>
                      Holaport, shall have a general and continuing lien on any
                      and all property of Customer coming into Holaport’s actual
                      or constructive possession or control for monies owed to
                      Holaport with regard to the shipment on which the lien is
                      claimed; a prior shipment(s) and/or both;
                    </li>
                    <li>
                      Holaport, shall provide written notice to Customer of its
                      intent to exercise such lien, the exact amount of monies
                      due and owing, as well as any on-going storage or other
                      charges; Holaport, shall notify all parties having an
                      interest in its shipment(s) of Holaport’s rights and/or
                      the exercise of such lien.
                    </li>
                  </ol>
                </li>
                {/* <li className="d-none">
                  {" "}
                  <span>
                    No Liability for the Selection or Services of Sellers, Third
                    Parties and/or Routes
                  </span>
                  :
                  <p>
                    Unless services are performed by persons or firms engaged
                    pursuant to express written instructions from the Customer,
                    Holaport shall use reasonable care in connecting the
                    Customer with the Cargo Partner. Cargo Partner may recommend
                    the options and routes for the shipment and the Customer
                    shall confirm and choose the route for the shipment. ,. The
                    Customer understands and agrees that if a particular Cargo
                    Partner is identified by Holaport to render the Services, it
                    shall not be construed to mean that Holaport warrants or
                    represents that such Cargo Partner, person or firm will
                    render such services, nor does Holaport assume
                    responsibility or liability for any and all actions(s)
                    and/or inaction(s) of such Cargo Partner, third parties
                    and/or its agents, and shall not be liable for any delay or
                    loss of any kind, which occurs while a shipment is in the
                    custody or control of a Cargo Partner/ Freight Forwarder,
                    third party or the agent of a third party. All claims in
                    connection with the act of the Cargo Partner, third party
                    shall be brought solely against such a party; in connection
                    with any such claim, Holaport shall reasonably cooperate
                    with the Customer.
                  </p>
                </li> */}
                <li>
                  {" "}
                  <span>Liability</span>:
                  <ol className="romanPoints">
                    <li>
                      Holaport will not be liable for damage or delays in any
                      form (including, but not limited to, loss of data,
                      indirect damages, consequential loss, pure economic loss,
                      loss of opportunity, loss of revenue or profit, wrongful
                      death, personal injury, property damage, penalties and
                      fines, costs or any other loss) sustained by the Customer,
                      your servants, agents, employees, insurers and third
                      parties for whom you are liable pursuant to any legal
                      relationship, unless you prove that the damage is a direct
                      result of Holaport’s gross negligence or willful
                      misconduct. The total liability of Holaport shall not
                      exceed the total amount of charges collected by Holaport
                      from the Customer for the individual services performed
                      giving rise to the claim or cause of action.
                    </li>
                    <li>
                      {" "}
                      Holaport will not be responsible for any damage to the
                      cargo if the Customer does not adhere to the standard
                      packaging norms recommended by airlines for Air
                      movement/shipment.
                    </li>
                    <li>
                      {" "}
                      Holaport will not be liable if the cargo is held under
                      customs due to the misdeclaration of Harmonised System
                      (HS) codes and products by the Customer.
                    </li>
                    <li>
                      {" "}
                      Holaport will not be liable if the Customer&#39;s
                      Importer-Exporter Code (IEC) gets flagged by customs due
                      to misdeclaration of cargo or documents by the Customer.
                    </li>
                    <li>
                      {" "}
                      Holaport will not bear any charges such as storage
                      charges, and demurrage charges that are incurred due to
                      customs examinations and customs hold both in origin and
                      destination.
                    </li>
                    <li>
                      {" "}
                      Holaport will not be liable to pay any penalties or losses
                      incurred to other parties due to the nature of a
                      Customer&#39;s cargo.
                    </li>
                    <li>
                      {" "}
                      Holaport shall not be liable for misclassification of
                      Harmonised tariff schedule (“HTS”) codes. HTS codes shall
                      be suggested to the Customer as a courtesy. However,
                      deciding the final HTS codes to be submitted to US customs
                      is the Customer&#39;s sole responsibility. These HTS codes
                      should be sent before the clearance is filed. Holaport
                      shall provide notice about filing &amp; give 48 hours to
                      suggest the HTS before we use our recommended HTS.
                    </li>
                    <li>
                      {" "}
                      Holaport is not liable to any penalties/ liquidation
                      damages, misclassification charges, or duty differences
                      implied by US Customs. The Customer is liable to pay all
                      these charges.
                    </li>
                    <li>
                      {" "}
                      Holaport is only liable to deliver the goods to the
                      destination. We are not responsible if there is a delay in
                      inwarding of SKUs at the warehouse or if there is a delay
                      in the Customer’s inventory going live on the concerned
                      e-commerce platform.
                    </li>
                    <li>
                      The Customer understands that Holaport shall collect details of
                      Employer Identification Number ("EIN") while billing US based or
                      foreign entities. Holaport shall not be responsible to verify the
                      EIN details provided and will not be liable for any incorrect,
                      misleading or false information provided.
                    </li>
                    <li>
                      Holaport shall not be liable for any incomplete,
                      wrongful, incorrect or misleading information provided to
                      Holaport by the Customer for the services.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>Engagement of Third Parties</span>: “
                  <span style={{ fontSize: "0.99rem", fontWeight: "600" }}>
                    Third Parties or Third Party
                  </span>
                  ” shall include, but not limited to the following - "carriers,
                  truckmen, cartmen, lightermen, forwarders, OTIs, customs
                  brokers, agents, warehouse men and others to which the goods
                  are entrusted for transportation, cartage, handling and/or
                  delivery and/or storage or otherwise". Unless services are
                  performed by persons or firms engaged pursuant to express
                  written instructions from you, we shall use reasonable care in
                  our selection of Third Parties, or in selecting the means,
                  route and procedure to be followed in the handling,
                  transportation, clearance and delivery of the shipment.
                  Communication by us that a particular person or firm has been
                  selected to render services with respect to the goods, shall
                  not be construed to mean that we warrant or represent that
                  such person or firm will render such services nor do we assume
                  responsibility or liability for any actions(s) and/or
                  inaction(s) of such Third Parties and/or its agents, and shall
                  not be liable for any delay or loss of any kind due to the
                  negligence of a Third Party or the agent of a Third Party. All
                  claims in connection with the act of a Third Party shall be
                  brought solely against such party and/or its agents; in
                  connection with any such claim, we shall reasonably cooperate
                  with you, who shall be liable for any charges or costs
                  incurred by us.
                </li>
                <li>
                  {" "}
                  <span>Quotations Not Binding</span>: Quotations as to fees,
                  rates of duty, freight charges, insurance premiums or other
                  charges given by Holaport, are for informational purposes only
                  and are subject to change without notice. No quotation shall
                  be binding upon Holaport unless Holaport in writing agrees to
                  undertake the handling or transportation of the shipment at a
                  specific rate or amount set forth in the quotation and payment
                  arrangements are agreed to between Holaport and the Customer.
                  Notwithstanding the same, these terms and conditions remain
                  binding on the Customer.
                </li>
                <li>
                  {" "}
                  <span>Goods & Service Tax (GST)</span>: Goods &amp; Service
                  Tax, shall apply in addition, on services, as notified by the
                  Government of India, at the rate notified by the Government of
                  India.
                </li>
                <li>
                  {" "}
                  <span>Freight Validity</span>: The freight rate will remain
                  valid for a period mentioned by Holaport or for any other
                  stated validity period after which time it will be subject to
                  review. The rate remains subject to any accessorial charges
                  announced by the Holaport during this period.
                </li>
                <li>
                  {" "}
                  <span>Surcharges Subject Change</span>: All surcharges are
                  subject to change without prior notice. All applicable
                  surcharges at the time of shipment will apply. Rates quoted
                  are not applicable for shipments that are restricted, high
                  density, odd dimensional, volume, dangerous, perishable or
                  which require special handling.
                </li>
                <li>
                  {" "}
                  <span>Additional Charges</span>: The quoted freight rate is
                  exclusive of additional charges and is subject to prevailing
                  accessorial surcharges, Special Equipment Surcharge, Over
                  Dimensional Surcharge. by accepting the Quotation and
                  confirming the Services, the Customer agrees to pay any extra
                  lashings that are required to adequately stow the cargo, by
                  direct payment to the supplier or to Holaport by prior
                  arrangement.
                </li>
                <li>
                  {" "}
                  <span>Terms of Quotation</span>: You, as a Customer, agree to
                  pay the amounts presented to you in the final Quotation which
                  is accepted by you and confirmed by Holaport. Further, you,
                  the Customer agree to pay any other amounts that you owe to
                  Holaport in connection with the Service. You also agree to
                  provide any documentation reasonably requested by a Holaport
                  for the purposes of your shipment/Service and you are
                  responsible for the accuracy and timely submission of such
                  documentation to Holaport, whether that occurs through the
                  Xhipment’s digital platform or otherwise. We will use
                  reasonable efforts to ensure that quotes displayed on
                  Xhipment’s digital platform or otherwise are all- inclusive.
                  However, as we have already explained, in addition to these
                  terms and conditions, you may be charged an amount that is
                  additional to the amount as mentioned in the Quotation in
                  certain circumstances. For example, if the truck waits hours
                  for your cargo the truck waits hours for delivery of your
                  cargo at destination or your cargo weighs more than you
                  declared, you will incur extra charges in addition to what
                  appeared in the relevant Quote Similarly, we will disclaim
                  liability for delays caused by force majeure events including
                  weather and strikes. Please note, these are only examples and
                  are not limiting in any manner.
                </li>
                <li>
                  {" "}
                  <span>Cancellation:</span>: In case of cancellation a
                  cancellation fee shall be applicable. Please contact Holaport
                  for details regarding the cancelation fee.
                </li>
                <li>
                  {" "}
                  <span>Indemnity</span>: You will indemnify, defend and hold
                  Holaport and its officers, directors, employee and agents
                  harmless, from and against any claims, disputes, demands,
                  liabilities, damages, losses, and costs and expenses,
                  including, without limitation, reasonable legal and accounting
                  fees arising out of or in any way connected with
                  <ol className="romanPoints">
                    <li>
                      your access to or use of Holaport or Holaport digital
                      platform and/ or any services made available by Holaport,
                    </li>
                    <li>your Customer content, or</li>
                    <li>your violation of these terms and conditions.</li>
                  </ol>
                </li>

                <li>
                  {" "}
                  <span>Services of logistics service providers</span>: The
                  Company engages with logistics service providers to provide
                  the requested services to its customers. If you provide any
                  incomplete or wrong or insufficient information or fail to
                  disclose any required information under law, then you are
                  vicariously liable for the acts of the Company. You agree that
                  you shall indemnify and hold us harmless from any and all
                  claims, losses, penalties or other costs resulting from any
                  such default. In addition to that you hereby agree that if any
                  loss incurred by us, either directly or indirectly, due to any
                  default by you, then you are liable for damages towards the
                  Company.
                </li>

                <li>
                  Customer hereby waives all rights of subrogation against
                  Holaport, its officers, members, overseas partners, and
                  employees, arising out of any loss of or damage to goods or
                  property over the course of the shipment or services.
                </li>

                <li>
                  {" "}
                  <span>Force Majeure</span>: If at any time Holaport ’s
                  performance is or is likely to be affected by any hindrance or
                  risk of any kind not arising from any fault or neglect of
                  Holaport and beyond the control of Holaport (a “Force Majeure
                  Event”) and which cannot be avoided by the exercise of
                  reasonable endeavor, Holaport may be excused from performance
                  only to the extent made necessary by the Force Majeure Event
                  and only for the duration of the Force Majeure Event, at no
                  liability to Holaport or Customer. A party affected by a Force
                  Majeure Event shall notify the other party as soon as
                  possible, and the parties shall work together in good faith to
                  find alternative solutions for continuation of the shipment.
                  Should the Force Majeure Event persist for or exceed 30
                  consecutive days the Customer shall have the right to cancel
                  the shipment with no liability to Holaport, other than payment
                  incurred for services rendered prior to the Force Majeure
                  Event. Holaport shall use reasonable commercial steps to
                  cooperate with Customer in securing the Shipment and shall
                  assist in its handover to Customer or its designated
                  representative required for the same.
                </li>
                <li>
                  {" "}
                  <span>Special Clauses</span>:
                  <ol className="alphaList">
                    <li>
                      Please note that our offer is based on the conditions
                      known at this time, as the logistics industry is severely
                      affected by the Covid-19 pandemic. As a result, there may
                      be an increase in transportation costs, or there may be
                      difficulties that are not known in advance. Of course, we
                      will keep you informed of the circumstances and cost
                      incurred. The additional costs whatever the reason-cannot
                      be covered by Holaport and must be paid by you in
                      accordance with the new/revised quotation and the
                      Holaport’s Standard Terms and Conditions.
                    </li>
                    <li>
                      {" "}
                      For the avoidance of doubt Holaport cannot be held liable
                      in case of any delays and/ or default in the performance
                      of Services as mentioned under the terms and
                      conditions/agreement/quotation/commercials due to any
                      measures taken by, without limitation, public authorities,
                      port authorities, shipping and any other transportation
                      companies due to the Covid-19 virus, and/or any other
                      epidemic or pandemic outbreaks.
                    </li>
                    <li>
                      Holaport shall not be held liable for any damage or any
                      other additional charges that may accrue as a result of an
                      event of Force Majeure, including without limitation
                      storage charges, which shall be fully borne by Customer.
                    </li>
                  </ol>
                </li>
              </ul>
            </dd>
          </dl>

          <div className="LICENSING">
            <h2 className="text-center">END USER LICENSING AGREEMENT</h2>
            <div className="licensing-text">
              <p>
                PLEASE READ THE TERMS OF THIS END USER LICENSING AGREEMENT (“
                EULA”) CAREFULLY BEFORE CREATING AN ACCOUNT ON XHIPMENT’S
                PLATFORM.
              </p>
              <p>
                THIS EULA CONTAINS THE TERMS AND CONDITIONS THAT GOVERN YOUR USE
                OF THE PLATFORM ACCOUNT (DEFINED BELOW) AND IS EFFECTIVE FROM
                THE TIME YOU CREATE AN ACCOUNT ON PLATFORM.
              </p>
              <p>
                BY SIGNING UP, ACCESSING AND USING THE SERVICES, YOU SIGNIFY AND
                ACKNOWLEDGE THAT YOU HAVE READ THE TERMS OF THIS EULA AND AGREE
                TO BE BOUND BY THESE TERMS.
              </p>
              <p className="licensingP4">
                Terms not defined herein, derive their definition from the Terms
                of Use{" "}
                <Link to={"/Home/Terms"}>https://xhipment.com/Terms.html</Link>{" "}
                and Privacy Policy{" "}
                <Link to={"/Home/Privacy"}>
                  https://xhipment.com/Privacy.html
                </Link>{" "}
                . In addition to these terms under the EULA, the terms as
                detailed in the Terms of Use and Privacy Policy (as amended from
                time to time) shall remain applicable to Your use of the
                Platform.
              </p>
              <p>
                You may contact us at support@xhipment.com. By using the
                Platform, You can access and use the Services, including
                updates, either at no charge or for the price indicated.
                Services available on the Platform may be provided by the
                Company.
              </p>

              <ol className="numberList">
                <li>
                  . Accepting the terms of this EULA
                  <ol className="numberList mt-2">
                    <li>
                      . In order to use the Service, You must first agree to the
                      terms of this EULA. If You do not accept the terms of this
                      EULA, You may not use the Service; accordingly, Your use
                      of the Services constitutes acceptance of the terms
                      contained in this EULA.
                    </li>
                    <li>
                      . The Company may modify the terms of this EULA from time
                      to time. If You continue to use the Service after such
                      modification, You will be deemed to have read, understood
                      and unconditionally agreed to such changes.
                    </li>
                    <li>
                      . You may not use the Services or provide us with personal
                      information if: (a) You are under the age of 18; or (b)
                      You are a person barred from receiving the Services under
                      the applicable laws of the jurisdiction in which You are a
                      resident or from which You use the Services.
                    </li>
                    <li>
                      . You agree that you understand, read and write in the
                      English language and that You have read, understood and
                      agree to be bound by the terms of this EULA.
                    </li>
                  </ol>
                </li>
                <li>
                  . Service Availability
                  <p className="mt-4">
                    The Services are made available only to Users who have
                    registered with the Company.
                  </p>
                </li>
                <li>
                  . Registration
                  <ol className="numberList mt-2">
                    <li>
                      . In order to access the Services, You will need to
                      provide certain information as part of the registration
                      process, or as part of Your continued use of the Services.
                      You agree to provide accurate, current and complete
                      information required to register with the Service, and at
                      other points as may be required in the course of using the
                      Service (“ Registration Information”). You further agree
                      to maintain and update Your Registration Information as
                      required to keep it accurate, current and complete. The
                      Company may terminate Your rights to all or part of the
                      Service if any information You provide is false,
                      inaccurate or incomplete.
                    </li>
                    <li>
                      . You may be required to provide additional information or
                      access to other information such as but not limited to
                      location, name, email addresses, mobile number, company
                      name, company website, designation, registered addresses
                      etc. to create Your account to use the Services (“
                      Account”).
                    </li>
                    <li>
                      . You understand that You are responsible for maintaining
                      the confidentiality of Your username and passwords. The
                      Company is not responsible for any issues arising from or
                      relating to Your Account due to Your failure to protect
                      Your username and/or passwords. If You become aware of, or
                      suspect any unauthorized use of Your username, password or
                      of Your Account You must notify us immediately at
                      support@xhipment.com.
                    </li>
                  </ol>
                </li>
                <li>
                  . Privacy
                  <p className="mt-4">
                    The Company respects Your privacy. In order to make the
                    Services available to You and process the transactions made
                    by You, the Company may collect certain information and
                    personal data from You, and use such information and data in
                    accordance with our Privacy Policy. The Company may update
                    the Privacy Policy, located at{" "}
                    <Link to={"/Home/Privacy"}>
                      {" "}
                      https://xhipment.com/Privacy.html
                    </Link>{" "}
                    from time to time, so please periodically review the Privacy
                    Policy.
                  </p>
                </li>
                <li>
                  . License of Third Party Services
                  <ol className="numberList mt-2">
                    <li>
                      . You acknowledge that your license of each third party
                      content through the Services is governed by a separate
                      agreement between You and the third party content provider
                      only, and the third party content provider is solely
                      responsible for (a) the licensed third party content, (b)
                      the content, services, advertisements and functionalities
                      therein, (c) any warranties provided, and (d) any claims
                      you may have relating to or your use of such third party
                      content. You acknowledge that the Company is (i) acting
                      solely as a distributor in providing the third party
                      content to you, (ii) is not a party to such license, and
                      (iii) is not responsible for the third party content, the
                      content and functionalities therein, or any warranties or
                      claims you may have relating to or your use of the third
                      party content.
                    </li>
                  </ol>
                </li>
                <li>
                  . Proprietary Rights
                  <ol className="numberList mt-2">
                    <li>
                      . The Company and Company’s licensors as applicable own
                      all right, title and interest in and to the Services and
                      the Platform, including all associated intellectual
                      property rights, whether registered or not, and wherever
                      in the world such rights may exist.
                    </li>
                    <li>
                      . Nothing in these Terms gives You a right to use any of
                      the Company’s trade names, trademarks, service marks,
                      logos, domain names, and other distinctive brand features.
                    </li>
                    <li>
                      . You agree that you shall not remove, obscure, or alter
                      any proprietary rights notices (including copyright and
                      trade mark notices) which may be affixed to or contained
                      within the Services.
                    </li>
                  </ol>
                </li>
                <li>
                  . Software updates and availability of Services
                  <ol className="numberList mt-2">
                    <li>
                      . The Platform may require You to download and install
                      updates as released by the Company from time to time.
                      These updates may improve, enhance and further develop the
                      Services and may take the form of bug fixes, enhanced
                      functions, new software modules or completely new
                      versions. You agree to receive and allow the Company to
                      deliver such updates to You as part of Your use of the
                      Services. We recommend that you check availability of any
                      new updates periodically for optimal use of the Platform.
                    </li>
                    <li>
                      . The Company reserves the right, at its sole discretion,
                      to modify the Services at any time, with or without notice
                      to You. The Services may not be available at all times,
                      such as during maintenance breaks. The Company may decide,
                      at its sole discretion, to suspend or terminate the
                      Services (or any part thereof) at any time.
                    </li>
                  </ol>
                </li>
                <li>
                  . Termination
                  <ol className="numberList mt-2">
                    <li>
                      . These Terms will continue to apply until terminated by
                      You or by the Company in accordance with the terms herein.
                    </li>
                    <li>
                      . If you want to terminate your Account, please notify us
                      at support@xhipment.com. The Company will terminate Your
                      Account promptly upon receipt of Your notice and provided
                      that You have settled all outstanding amounts associated
                      with Your Account. When Your account is terminated, all
                      data or other information associated with your Account may
                      be deleted permanently. The Company shall have no
                      liability for such deletion resulting from Your Account
                      termination.
                    </li>
                    <li>
                      . The Company, at any time and for any reason, terminate
                      your Account or Your use of the Services by providing You
                      with written notice of termination at the email address
                      you provided in Your Registration Information. You will be
                      required to settle all outstanding amounts due associated
                      with Your Account immediately upon termination.
                    </li>
                  </ol>
                </li>
              </ol>

              <p>
                All other terms including but not limited to Disclaimers and
                Limitation of Liability, Indemnification, Advertisement, General
                Legal Terms as mentioned in the Terms of Use
                https://xhipment.com/Terms.html shall be applicable to You under
                this EULA.
              </p>
              <p className="text-center endTextsym">--x--</p>
              <p className="end">
                <span>Disclaimer</span>: The website has no cookies
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Terms;
